// app/javascript/packs/controllers/flash_controller.js
import anime from "animejs";
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const heightElement = document.querySelector(this.data.get("height"));
    if (heightElement) {
      this.element.style.height = `${heightElement.clientHeight}px`;
    }
    const height = this.element.clientHeight;
    var newURL = location.href.split("?")[0];
    window.history.pushState("object", document.title, newURL);
    anime({
      targets: this.element,
      translateY: [
        { value: -height, duration: 0 },
        { value: 0, duration: 400, easing: "easeOutElastic" },
        { value: -height, duration: 350, delay: 2500, easing: "easeInQuad" },
      ],
      complete: () => {
        this.element.parentNode.removeChild(this.element);
      },
    });
  }
}
